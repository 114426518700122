
import footerMenu from "@/footerMenu.json";

export default {
  async fetch() {
    this.footerMenu = footerMenu[this.activeLang];
  },

  data() {
    return {
      footerMenu: {},
      lang: this.$api.list.getLangFromPath(this.$route.path),
    };
  },

  computed: {
    activeLang() {
      return this.lang === "" ? "tr" : this.lang;
    },
  },
};
