
import topMenu from "@/topMenu.json";

export default {
  async fetch() {
    this.topMenu = topMenu[this.activeLang];
  },

  data() {
    return {
      topMenu: {},
      showMobileMenu: false,
      currLang: "",
      setLang: "",
      lang: this.$api.list.getLangFromPath(this.$route.path),
    };
  },

  watch: {
    $route(to, from) {
      this.showMobileMenu = false;
    },
  },

  methods: {
    setLanguage() {
      this.lang = this.activeLang == "tr" ? "en" : "tr";

      // let redirectRoute = this.currLang == 'en' ? '/en' : '/';
      window.location.href = `/${this.activeLang}`;

      // this.$store.commit('upd_lang', lan);
    },
    runAccordions(e) {
      let menu = document.querySelector(".mobile-menu");
      let item = document.querySelectorAll(".mobile-menu-collapse-item");

      [].forEach.call(item, function (el) {
        el.nextElementSibling.classList.remove("active");
        el.classList.remove("active");
      });

      e.target.classList.toggle("active");
      e.target.nextElementSibling.classList.toggle("active");
    },
  },

  mounted() {
    this.setLang = this.activeLang === "tr" ? "en" : "tr";
  },

  computed: {
    activeLang() {
      return this.lang === "" ? "tr" : this.lang;
    },
  },
};
